

















import { SMRoot } from "@/store";
import { Component, Vue } from "vue-property-decorator";

@Component
export default class Top extends Vue {
  protected get styles() {
    return {
      paddingTop: `${SMRoot.fixed.height}px`,
    };
  }
}
